import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../../assets/icons/logo.svg";
import { check, noCheck } from "../svgs";

interface PropsCellTable extends PropsWithChildren<any> {
  feature_id: string;
  plans: any[];
}

const CellTable: React.FC<PropsCellTable> = (props: PropsCellTable) => {
  const status_render = [];

  props.plans.forEach((plan) => {
    const status = plan.features.find(
      (element) => element._id === props.feature_id
    )
      ? check
      : noCheck;
    status_render.push(
      <td key={plan._id} className={plan.name}>
        {status}
      </td>
    );
  });

  return <>{status_render}</>;
};

export default CellTable;
