import * as React from "react";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";

import { Contact } from "../../Forms/Contact/Contact";
import ModalForm from "../../Modal/ModalForm";

interface PropsCellPrice extends PropsWithChildren<any> {
  plans: any[];
  openModal: any;
}

const RowPrices: React.FC<PropsCellPrice> = (props: PropsCellPrice) => {
  const row_render = [];
  const [is_active, setIsActive] = useState(false);
  const { t } = useTranslation("global");

  props.plans.forEach((plan) => {
    row_render.push(
      <td key={plan._id} className={`${plan.name} a-table-plans__cell-price`} style={{ color: plan.color }}>
        {plan.price > 0 && <>{plan.price}€</>}
        {plan.price === 0 && (
          <button
            className={"a-button a-button--small"}
            onClick={() => {
              props.openModal(true);
            }}
          >
            {t("plans.resume__request-price")}
          </button>
        )}
      </td>
    );
  });

  return (
    <>
      {is_active && (
        <ModalForm title={"Solicitar información"} from={"Consulta servicio"} is_active={setIsActive} form={Contact} />
      )}
      <tr className={"m-table-plans__row-prices"}>
        <th className={"is-text-left"} scope={"row"}>
          Tarifa mensual
        </th>
        {row_render}
      </tr>
    </>
  );
};

export default RowPrices;
