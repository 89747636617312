import "./table-plans.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../../../assets/icons/logo.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logoWhite from "../../../assets/icons/logo-white.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import arrowLeft from "../../../assets/vectors/left.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import arrowRight from "../../../assets/vectors/right.svg";
import Tooltip from "../../Tooltip/Tooltip";
import { icoInfo, leftIcon } from "../svgs";
import CellTable from "./CellTable";
import RowPrices from "./RowPrices";

type Plan = {
  _id: string;
  name: string;
  price: number;
  color: string;
  position: number;
};

interface PropsTable extends PropsWithChildren<any> {
  plans: any[];
  selectedPlan: Plan;
  changePlan: any;
  openModal: any;
}

const TablePlans: React.FC<PropsTable> = (props: PropsTable) => {
  const { t } = useTranslation("global");
  const [sms, setSms] = useState("Texto descriptivo");
  const [title, setTitle] = useState("Título");
  const [is_active, setActive] = useState(false);
  const [position, setPosition] = useState<number[]>([0, 0]);
  const headers_render = [];
  const rows_render = [];
  const columns = useRef(null);
  const selector = useRef(null);
  const { allSanityFeature: features } = useStaticQuery(graphql`
    query getFeatures {
      allSanityFeature(sort: { order: ASC, fields: position }) {
        nodes {
          position
          name {
            es
          }
          description {
            es
          }
          _id
        }
      }
    }
  `);

  props.plans.forEach((plan) => {
    headers_render.push(
      <th key={plan._id} id={plan.name} scope="col">
        <img className={"a-plans__logo"} src={logo} alt={""} width={"100"} />
        <p style={{ color: plan.color }}>{plan.name}</p>
      </th>
    );
  });

  features.nodes.forEach((feature) => {
    rows_render.push(
      <tr key={feature._id}>
        <th
          className={"is-text-left is-pointer"}
          scope={"row"}
          onClick={(event) => {
            clickIcon(feature.description.es, feature.name.es, event);
          }}
        >
          <span
            className={"a-plan__feature"}
            onClick={() => {
              setActive(false);
            }}
          >
            {feature.name.es}
          </span>

          <span
            className={"a-plan__info"}
            onClick={(event) => {
              clickIcon(feature.description.es, feature.name.es, event);
            }}
          >
            {icoInfo}
          </span>
        </th>
        <CellTable feature_id={feature._id} plans={props.plans} />
      </tr>
    );
  });

  useEffect(() => {
    const move = columns.current.children[props.selectedPlan.position].offsetLeft;
    const width = columns.current.children[props.selectedPlan.position].clientWidth + 10;
    selector.current.style.transform = `translateX(${move}px)`;
    selector.current.style.borderColor = props.selectedPlan.color;
    selector.current.style.width = `${width}px`;
  }, [props.selectedPlan]);

  function clickIcon(_sms, _title, event) {
    if (window.innerWidth && window.innerWidth < 1024) {
      setPosition([15, event.pageY + 32]);
    } else {
      setPosition([event.pageX + 20, event.pageY - 100]);
    }
    setSms(_sms);
    setTitle(_title);
    setActive(true);
  }

  return (
    <>
      <section className={`o-table-plans ${is_active ? "o-table-plans--trans" : ""}`} id={"table-plans"}>
        <div className={"m-resume-and-table"}>
          <div className={"m-resume__select"} style={{ backgroundColor: props.selectedPlan.color }}>
            <p className={"a-title__card-service a-hidden-tablet"}>{t("plans.resume__title")}</p>
            <div className={"m-resume__name"}>
              <img src={logoWhite} width={"150"} alt={"logo wayup"} />
              <p className={"a-resume__name"}> {props.selectedPlan.name} </p>
            </div>
            {props.selectedPlan.price > 0 && (
              <div className="m-resume__price">
                <p className={"a-resume__price"}>
                  {props.selectedPlan.price}
                  <small>€</small>
                </p>
                <p className={"a-resume__price-info"}>{t("plans.resume__info-price")}</p>
              </div>
            )}
            {props.selectedPlan.price === 0 && (
              <button
                className={"a-button a-button--outline"}
                onClick={() => {
                  props.openModal(true);
                }}
              >
                {t("plans.resume__request-price")}
              </button>
            )}
            <div className="resume__navigation a-hidden-tablet">
              <img
                onClick={() => {
                  props.changePlan(-1);
                }}
                className={"is-pointer"}
                src={arrowLeft}
                alt={t("arrow-left")}
              />
              <img
                onClick={() => {
                  props.changePlan(+1);
                }}
                className={"is-pointer"}
                src={arrowRight}
                alt={t("arrow-right")}
              />
            </div>
          </div>
          <div className={"m-plans__table"}>
            <div ref={selector} className={"selector-table"} />
            <table className={""}>
              <thead
                onClick={() => {
                  setActive(false);
                }}
              >
                <tr ref={columns}>
                  <th className={""}>
                    <p className={"a-table-plans__indications"}>
                      <span className={"a-table-plans__indications__title"}>{t("plans.resume__title")}</span>
                      <span className={"a-table-plans__indication__arrows"}>
                        {leftIcon}
                        {leftIcon}
                        {leftIcon}
                      </span>
                    </p>
                  </th>
                  {headers_render}
                </tr>
              </thead>
              <tbody>
                {rows_render}
                <RowPrices plans={props.plans} openModal={props.openModal} />
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <Tooltip sms={sms} title={title} active={is_active} position={position} setActive={setActive} />
    </>
  );
};

export default TablePlans;
