import "./hero.scss";
import "./logo.scss";
import "./video.scss";
import "./svg.scss";
import "./ctas.scss";

import * as React from "react";
import { PropsWithChildren, useLayoutEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../../assets/icons/logo.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import thumbVideo from "../../assets/images/home-video.png";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import play from "../../assets/vectors/ico-play_white.svg";
import VimeoIframe from "../../components/common/iframe/VimeoIframe";

export type ContentHero = {
  title: string;
  text: string;
  ctas: Element[];
};

interface PropsHero extends PropsWithChildren<any> {
  content: ContentHero;
  video: string;
}

const Hero: React.FC<PropsHero> = (props: PropsHero) => {
  const [is_fixed, setFixed] = useState(true);
  const [is_run, setRun] = useState(true);
  const [is_playVideo, setIsPlayVideo] = useState(false);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 1,
  });

  //<editor-fold desc="svgs">
  const svgTop = (
    <svg
      id={"svgHeroTop"}
      xmlns="http://www.w3.org/2000/svg"
      width="145"
      height="220.447"
      viewBox="0 0 240.661 220.447"
    >
      <g id="Grupo_168" transform="translate(-839.238 69.583)">
        <g id="Grupo_167">
          <path
            id="Trazado_6"
            d="M226.9,199.914h0a12.864,12.864,0,0,1-1.947-18.118L338,40.3a12.864,12.864,0,0,1,18.118-1.947h0a12.864,12.864,0,0,1,1.947,18.118l-113.049,141.5A12.864,12.864,0,0,1,226.9,199.914Z"
            transform="translate(617.15 -51.914)"
            fill="#5600cb"
          />
          <path
            id="Trazado_7"
            d="M294.9,164.414h0a12.864,12.864,0,0,1-1.947-18.118L406,4.8a12.864,12.864,0,0,1,18.118-1.947h0a12.864,12.864,0,0,1,1.946,18.118l-113.049,141.5A13.142,13.142,0,0,1,294.9,164.414Z"
            transform="translate(650.97 -69.57)"
            fill="#5600cb"
          />
          <circle id="Elipse_10" cx="12.759" cy="12.759" r="12.759" transform="translate(915.538 104)" fill="#5600cb" />
        </g>
      </g>
    </svg>
  );

  const svgBottom = (
    <svg id={"svgHeroBottom"} xmlns="http://www.w3.org/2000/svg" width="250" height="270" viewBox="0 0 358.047 345.287">
      <defs>
        <clipPath id="clip-path">
          <circle
            id="Elipse_3"
            cx="142.48"
            cy="142.48"
            r="142.48"
            transform="translate(0 276.554) rotate(-76.043)"
            opacity="0.15"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient"
          x1="-2.02"
          y1="-9.794"
          x2="-0.365"
          y2="-7.695"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#99c9ce" />
          <stop offset="1" stopColor="#5600cb" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-2.02"
          y1="-9.794"
          x2="-0.365"
          y2="-7.695"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#99c9ce" />
          <stop offset="0.515" stopColor="#7867cd" />
          <stop offset="1" stopColor="#5600cb" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="0.055"
          y1="-0.179"
          x2="1.35"
          y2="1.801"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#de705d" />
          <stop offset="1" stopColor="#d82b64" />
        </linearGradient>
      </defs>
      <g id="Grupo_169" transform="translate(123.674 -859.008)">
        <g id="Grupo_55" transform="translate(-123.674 859.008)" opacity="0.15">
          <g id="Grupo_54" transform="translate(0 0)" clipPath="url(#clip-path)">
            <g id="Grupo_53" transform="translate(20.494 26.663)">
              <rect
                id="Rectángulo_5"
                width="294.352"
                height="294.352"
                fill="none"
                stroke="#2d293a"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <line
                id="Línea_85"
                x2="294.62"
                transform="translate(0 245.517)"
                fill="none"
                stroke="#2d293a"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <line
                id="Línea_86"
                x2="294.62"
                transform="translate(0 196.414)"
                fill="none"
                stroke="#2d293a"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <line
                id="Línea_87"
                x2="294.62"
                transform="translate(0 147.31)"
                fill="none"
                stroke="#2d293a"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <line
                id="Línea_88"
                x2="294.62"
                transform="translate(0 98.207)"
                fill="none"
                stroke="#2d293a"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <line
                id="Línea_89"
                x2="294.62"
                transform="translate(0 49.103)"
                fill="none"
                stroke="#2d293a"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <line
                id="Línea_90"
                y2="294.62"
                transform="translate(245.517)"
                fill="none"
                stroke="#2d293a"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <line
                id="Línea_91"
                y2="294.62"
                transform="translate(196.414)"
                fill="none"
                stroke="#2d293a"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <line
                id="Línea_92"
                y2="294.62"
                transform="translate(147.31)"
                fill="none"
                stroke="#2d293a"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <line
                id="Línea_93"
                y2="294.62"
                transform="translate(98.207)"
                fill="none"
                stroke="#2d293a"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <line
                id="Línea_94"
                y2="294.62"
                transform="translate(49.103)"
                fill="none"
                stroke="#2d293a"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
        </g>
        <path
          id="Trazado_8"
          d="M34,476.382h0a13.143,13.143,0,0,1-1.989-18.511L147.515,313.3a13.143,13.143,0,0,1,18.511-1.989h0a13.143,13.143,0,0,1,1.989,18.511L52.513,474.393A13.143,13.143,0,0,1,34,476.382Z"
          transform="translate(-86.298 673.987)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Trazado_9"
          d="M84.4,467.082h0a13.143,13.143,0,0,1-1.989-18.511L197.915,304a13.143,13.143,0,0,1,18.511-1.989h0a13.143,13.143,0,0,1,1.989,18.511l-115.5,144.569A13.128,13.128,0,0,1,84.4,467.082Z"
          transform="translate(-12.487 641.8)"
          fill="url(#linear-gradient-2)"
        />
        <circle
          id="Elipse_11"
          cx="12.759"
          cy="12.759"
          r="12.759"
          transform="translate(208.854 909.495)"
          fill="url(#linear-gradient)"
        />
        <circle
          id="Elipse_12"
          cx="18.604"
          cy="18.604"
          r="18.604"
          transform="translate(76.722 872.286)"
          fill="url(#linear-gradient-4)"
        />
      </g>
    </svg>
  );
  const svgStroke = (
    <svg
      id={"svgHeroStroke"}
      xmlns="http://www.w3.org/2000/svg"
      width="450"
      height="255.921"
      viewBox="0 0 597.729 255.921"
    >
      <g id="Grupo_171" transform="translate(-1079.899 -757.149)">
        <g id="Grupo_170">
          <g id="_2" transform="translate(1079.896 885.207) rotate(-11)">
            <g id="Grupo_74" transform="translate(0 0.011)">
              <path
                id="Trazado_16"
                d="M45.138,10.653C68.967,17.985,87.3,33.565,104.71,50.979c16.726,16.726,33.223,34.254,54.99,44.45,44.794,20.965,97.378,10.311,141.255-7.676C349.988,67.7,396.042,37.46,448.97,28.639c26.006-4.353,53.844-2.291,76.413,12.258,18.788,12.029,28.87,31.046,33.108,52.47,2.406,12.144,3.208,24.516,3.9,36.889H568c-.229-3.551-.344-6.988-.573-10.54-1.375-20.278-4.124-41.357-14.779-59C540.505,40.553,519.082,27.837,496.4,23.14c-27.724-5.614-56.365-.229-82.829,8.478C388.137,40.1,363.965,52.01,339.906,63.58c-46.054,22.225-97.149,44.679-149.5,35.285a109.478,109.478,0,0,1-35.056-12.373c-10.769-6.072-20.163-13.977-28.984-22.454C108.949,47.885,93.139,29.1,72.4,16.61A116.858,116.858,0,0,0,.115.686H0V6.528A111.572,111.572,0,0,1,45.138,10.653Z"
                transform="translate(0 -0.011)"
                fill="#db565f"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  //</editor-fold>

  useLayoutEffect(() => {
    if (inView && is_fixed && is_run) {
      setRun(false);
      setFixed(false);
      setTimeout(() => {
        setRun(true);
      }, 100);
    }
    if (!inView && !is_fixed && is_run) {
      setRun(false);
      setFixed(true);
      setTimeout(() => {
        setRun(true);
      }, 100);
    }
  }, [ref, inView]);

  return (
    <>
      <section className={"o-hero"}>
        {svgTop}
        <div className={"m-hero__content"}>
          <div className={"saveHeight__logo"}>
            <div className={`a-logo ${is_fixed ? "a-logo--pinned" : ""}`}>
              <img src={logo} width={273} height={166} />
            </div>
          </div>
          <h1 ref={ref}>{props.content.title}</h1>
          <p>{props.content.text} </p>
          <div className={"saveHeight__heroButtons"}>
            <div
              id={"ctas"}
              className={`
              m-hero__buttons
              ${is_fixed ? "m-hero__buttons--fixed" : ""}`}
            >
              {props.content.ctas}
            </div>
          </div>
          {svgBottom}
        </div>
        <div className={"m-hero__video back-some-gray"}>
          <div className={"m-hero__video__content"}>
            <img className={`a-hero__play ${is_playVideo ? "hidden" : ""}`} src={play} width={54} height={54} />
            {is_playVideo && (
              <div className={"a-hero__thumb"} onClick={() => setIsPlayVideo(false)}>
                <VimeoIframe code={props.video} title={props.content.title} />
              </div>
            )}
            {!is_playVideo && (
              <img
                onClick={() => setIsPlayVideo(true)}
                className={`a-hero__thumb`}
                src={thumbVideo}
                width={1280}
                height={720}
              />
            )}
            <div id={"HeroPluses"} className={"backgroundPluses"} />
            {svgStroke}
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
