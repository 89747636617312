import * as React from "react";

const strokeAndCircle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="582.416"
    height="345.287"
    viewBox="0 0 582.416 345.287"
    className={"strokeAndCircle"}
  >
    <defs>
      <clipPath id="clip-path">
        <circle
          id="Elipse_3"
          data-name="Elipse 3"
          cx="142.48"
          cy="142.48"
          r="142.48"
          transform="translate(0 276.554) rotate(-76.043)"
          opacity="0.15"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1="-2.02"
        y1="-9.794"
        x2="-0.365"
        y2="-7.695"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#99c9ce" />
        <stop offset="1" stopColor="#5600cb" />
      </linearGradient>
    </defs>
    <g
      id="Grupo_174"
      data-name="Grupo 174"
      transform="translate(-1484.033 -3226.356)"
    >
      <g
        id="_2"
        data-name="2"
        transform="translate(636.031 3790.493) rotate(-11)"
      >
        <g
          id="Grupo_74"
          data-name="Grupo 74"
          transform="translate(899.585 -183.715)"
        >
          <path
            id="Trazado_16"
            data-name="Trazado 16"
            d="M45.138,10.653C68.967,17.985,87.3,33.565,104.71,50.979c16.726,16.726,33.223,34.254,54.99,44.45,44.794,20.965,97.378,10.311,141.255-7.676C349.988,67.7,396.042,37.46,448.97,28.639c26.006-4.353,53.844-2.291,76.413,12.258,18.788,12.029,28.87,31.046,33.108,52.47,2.406,12.144,3.208,24.516,3.9,36.889H568c-.229-3.551-.344-6.988-.573-10.54-1.375-20.278-4.124-41.357-14.779-59C540.505,40.553,519.082,27.837,496.4,23.14c-27.724-5.614-56.365-.229-82.829,8.478C388.137,40.1,363.965,52.01,339.906,63.58c-46.054,22.225-97.149,44.679-149.5,35.285a109.478,109.478,0,0,1-35.056-12.373c-10.769-6.072-20.163-13.977-28.984-22.454C108.949,47.885,93.139,29.1,72.4,16.61A116.858,116.858,0,0,0,.115.686H0V6.528A111.572,111.572,0,0,1,45.138,10.653Z"
            transform="translate(0 -0.011)"
            fill="#db565f"
          />
        </g>
      </g>
      <circle
        id="Elipse_67"
        data-name="Elipse 67"
        cx="19.759"
        cy="19.759"
        r="19.759"
        transform="translate(1755.481 3379.241)"
        fill="url(#linear-gradient)"
      />
    </g>
  </svg>
);

const check = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="34.844"
    viewBox="0 0 46 47.844"
  >
    <g id="check-circle-svgrepo-com" transform="translate(-2.65 -2.25)">
      <path
        id="Trazado_28"
        data-name="Trazado 28"
        d="M26.572,50.094a23.922,23.922,0,1,1,0-47.844,1.84,1.84,0,0,1,0,3.68,20.258,20.258,0,1,0,14.4,34.447,1.848,1.848,0,1,1,2.625,2.6,23.775,23.775,0,0,1-17.027,7.115Z"
        transform="translate(0 0)"
        fill="#99c9ce"
      />
      <path
        id="Trazado_29"
        data-name="Trazado 29"
        d="M18.977,30.056a1.815,1.815,0,0,1-1.3-.54L7.863,19.7a1.84,1.84,0,0,1,2.6-2.6l8.514,8.514L38.605,5.987a1.84,1.84,0,1,1,2.6,2.6L20.351,29.443a1.79,1.79,0,0,1-1.374.613Z"
        transform="translate(6.859 4.58)"
        fill="#99c9ce"
      />
    </g>
  </svg>
);

const noCheck = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.114"
    height="34.114"
    viewBox="0 0 34.114 34.114"
  >
    <g
      id="Grupo_179"
      data-name="Grupo 179"
      transform="translate(-1246.711 -4631.616)"
    >
      <g id="check-circle-svgrepo-com" transform="translate(1246.711 4631.616)">
        <path
          id="Unión_3"
          data-name="Unión 3"
          d="M11.557,24.02a1.271,1.271,0,0,1-.424-.292,1.319,1.319,0,0,1-.275-.433,1.3,1.3,0,0,1-.085-.507V13.347H2.125a1.138,1.138,0,0,1-.144,0H1.334a1.282,1.282,0,0,1-.507-.085,1.246,1.246,0,0,1-.433-.276A1.242,1.242,0,0,1,.1,12.564a1.264,1.264,0,0,1-.1-.5,1.292,1.292,0,0,1,.394-.927,1.289,1.289,0,0,1,.94-.36h9.438V1.334a1.3,1.3,0,0,1,.085-.507,1.289,1.289,0,0,1,.7-.724,1.288,1.288,0,0,1,1.792,1.232v9.439h9.441a1.287,1.287,0,0,1,1.232,1.79,1.286,1.286,0,0,1-.724.7,1.3,1.3,0,0,1-.507.084H13.347V19h0v3c0,.047,0,.1,0,.143v.647a1.284,1.284,0,0,1-.361.94,1.25,1.25,0,0,1-.424.292,1.267,1.267,0,0,1-.5.1A1.288,1.288,0,0,1,11.557,24.02Z"
          transform="translate(17.057 0) rotate(45)"
          fill="#e6eaf7"
        />
      </g>
    </g>
  </svg>
);
const icoInfo = (
  <svg
    id="chat-question-svgrepo-com"
    xmlns="http://www.w3.org/2000/svg"
    width="17.953"
    height="18.009"
    viewBox="0 0 17.953 18.009"
  >
    <path
      id="Trazado_47"
      data-name="Trazado 47"
      d="M13.926,16.752a.966.966,0,1,1,.966-.966.979.979,0,0,1-.966.966Z"
      transform="translate(-4.638 -10.951)"
      fill="#cdd4e8"
    />
    <path
      id="Trazado_49"
      data-name="Trazado 49"
      d="M13.08,20.49a8.75,8.75,0,0,1-4-1,.75.75,0,1,1,.69-1.33,7.34,7.34,0,0,0,4.68.69A7.25,7.25,0,1,0,6,10a7.36,7.36,0,0,0,.85,5.49.729.729,0,0,1-1.25.75,8.724,8.724,0,1,1,9.08,4.11A9.609,9.609,0,0,1,13.08,20.49Z"
      transform="translate(-3.876 -2.992)"
      fill="#cdd4e8"
    />
    <path
      id="Trazado_50"
      data-name="Trazado 50"
      d="M5.33,21A1.42,1.42,0,0,1,4,19l1.53-3.42a.75.75,0,0,1,1.37.61L5.48,19.45l3.67-1.31a.75.75,0,1,1,.5,1.41L5.8,20.92A1.31,1.31,0,0,1,5.33,21Z"
      transform="translate(-3.876 -2.992)"
      fill="#cdd4e8"
    />
    <path
      id="Trazado_52"
      data-name="Trazado 52"
      d="M13.926,21.808c-.191,0-.378-.2-.537-.589a6.118,6.118,0,0,1-.356-1.568,12.221,12.221,0,0,1-.055-2.019,8.745,8.745,0,0,1,.264-1.789,1.815,1.815,0,0,1,.495-.956c.187-.135.382-.066.558.2a3.659,3.659,0,0,1,.433,1.287,10.962,10.962,0,0,1,.163,1.941,9.833,9.833,0,0,1-.287,2.458C14.425,21.425,14.181,21.8,13.926,21.808Z"
      transform="translate(-4.638 -8.178)"
      fill="#cdd4e8"
    />
  </svg>
);

const strokesAndGrid = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="345.287"
    height="378.547"
    viewBox="0 0 345.287 378.547"
    className={"d-strokesAndGrid"}
  >
    <defs>
      <clipPath id="clip-path">
        <circle
          id="Elipse_3"
          data-name="Elipse 3"
          cx="142.48"
          cy="142.48"
          r="142.48"
          transform="translate(0 276.554) rotate(-76.043)"
          opacity="0.15"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1="0.055"
        y1="-0.179"
        x2="1.35"
        y2="1.801"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#de705d" />
        <stop offset="1" stopColor="#d82b64" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="-2.02"
        y1="-9.794"
        x2="-0.365"
        y2="-7.695"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#99c9ce" />
        <stop offset="1" stopColor="#5600cb" />
      </linearGradient>
    </defs>
    <g
      id="Grupo_107"
      data-name="Grupo 107"
      transform="translate(-1752 -4213.74)"
    >
      <g
        id="Grupo_104"
        data-name="Grupo 104"
        transform="translate(1752 4247)"
        opacity="0.15"
      >
        <g
          id="Grupo_54"
          data-name="Grupo 54"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <g
            id="Grupo_53"
            data-name="Grupo 53"
            transform="translate(20.494 26.663)"
          >
            <rect
              id="Rectángulo_5"
              data-name="Rectángulo 5"
              width="294.352"
              height="294.352"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_85"
              data-name="Línea 85"
              x2="294.62"
              transform="translate(0 245.517)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_86"
              data-name="Línea 86"
              x2="294.62"
              transform="translate(0 196.414)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_87"
              data-name="Línea 87"
              x2="294.62"
              transform="translate(0 147.31)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_88"
              data-name="Línea 88"
              x2="294.62"
              transform="translate(0 98.207)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_89"
              data-name="Línea 89"
              x2="294.62"
              transform="translate(0 49.103)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_90"
              data-name="Línea 90"
              y2="294.62"
              transform="translate(245.517)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_91"
              data-name="Línea 91"
              y2="294.62"
              transform="translate(196.414)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_92"
              data-name="Línea 92"
              y2="294.62"
              transform="translate(147.31)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_93"
              data-name="Línea 93"
              y2="294.62"
              transform="translate(98.207)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_94"
              data-name="Línea 94"
              y2="294.62"
              transform="translate(49.103)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </g>
      <circle
        id="Elipse_69"
        data-name="Elipse 69"
        cx="11.296"
        cy="11.296"
        r="11.296"
        transform="translate(1859.704 4460.1)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Trazado_57"
        data-name="Trazado 57"
        d="M34,476.382h0a13.143,13.143,0,0,1-1.989-18.511L147.515,313.3a13.143,13.143,0,0,1,18.511-1.989h0a13.143,13.143,0,0,1,1.989,18.511L52.513,474.393A13.143,13.143,0,0,1,34,476.382Z"
        transform="matrix(-0.259, 0.966, -0.966, -0.259, 2314.386, 4450.638)"
        fill="url(#linear-gradient-2)"
      />
      <path
        id="Trazado_58"
        data-name="Trazado 58"
        d="M34,476.382h0a13.143,13.143,0,0,1-1.989-18.511L147.515,313.3a13.143,13.143,0,0,1,18.511-1.989h0a13.143,13.143,0,0,1,1.989,18.511L52.513,474.393A13.143,13.143,0,0,1,34,476.382Z"
        transform="matrix(-0.259, 0.966, -0.966, -0.259, 2314.386, 4341.958)"
        fill="url(#linear-gradient-2)"
      />
      <circle
        id="Elipse_70"
        data-name="Elipse 70"
        cx="12.759"
        cy="12.759"
        r="12.759"
        transform="translate(1812.167 4213.74)"
        fill="url(#linear-gradient-2)"
      />
    </g>
  </svg>
);
const leftIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33.082"
    height="16.327"
    viewBox="0 0 33.082 16.327"
  >
    <g id="Grupo_85" transform="translate(31.582 14.206) rotate(180)">
      <line
        id="Línea_97"
        y1="5.902"
        x2="6.398"
        transform="translate(23.062 6.185)"
        fill="none"
        stroke="#5600CB"
        strokeLinecap="round"
        strokeWidth="3"
      />
      <line
        id="Línea_98"
        x2="6.398"
        y2="6.185"
        transform="translate(23.062)"
        fill="none"
        stroke="#5600CB"
        strokeLinecap="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);

export { check, icoInfo, leftIcon, noCheck, strokeAndCircle, strokesAndGrid };
