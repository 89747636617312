import * as React from "react";

const linesAndGrid = (
  <svg
    className={"linesAndGrid"}
    xmlns="http://www.w3.org/2000/svg"
    width="350"
    height="350"
    viewBox="0 0 366.855 385.843"
  >
    <defs>
      <clipPath id="clip-path">
        <circle
          id="Elipse_3"
          data-name="Elipse 3"
          cx="142.48"
          cy="142.48"
          r="142.48"
          transform="translate(0 276.554) rotate(-76.043)"
          opacity="0.15"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1="-2.02"
        y1="-9.794"
        x2="-0.365"
        y2="-7.695"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#99c9ce" />
        <stop offset="1" stopColor="#5600cb" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="-2.02"
        y1="-9.794"
        x2="-0.365"
        y2="-7.695"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#99c9ce" />
        <stop offset="0.515" stopColor="#7867cd" />
        <stop offset="1" stopColor="#5600cb" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1="0.055"
        y1="-0.179"
        x2="1.35"
        y2="1.801"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#de705d" />
        <stop offset="1" stopColor="#d82b64" />
      </linearGradient>
    </defs>
    <g
      id="Grupo_173"
      data-name="Grupo 173"
      transform="translate(37.855 -2632.74)"
    >
      <g
        id="Grupo_91"
        data-name="Grupo 91"
        transform="translate(-16.287 2673.296)"
        opacity="0.15"
      >
        <g
          id="Grupo_54"
          data-name="Grupo 54"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <g
            id="Grupo_53"
            data-name="Grupo 53"
            transform="translate(20.494 26.663)"
          >
            <rect
              id="Rectángulo_5"
              data-name="Rectángulo 5"
              width="294.352"
              height="294.352"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_85"
              data-name="Línea 85"
              x2="294.62"
              transform="translate(0 245.517)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_86"
              data-name="Línea 86"
              x2="294.62"
              transform="translate(0 196.414)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_87"
              data-name="Línea 87"
              x2="294.62"
              transform="translate(0 147.31)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_88"
              data-name="Línea 88"
              x2="294.62"
              transform="translate(0 98.207)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_89"
              data-name="Línea 89"
              x2="294.62"
              transform="translate(0 49.103)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_90"
              data-name="Línea 90"
              y2="294.62"
              transform="translate(245.517)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_91"
              data-name="Línea 91"
              y2="294.62"
              transform="translate(196.414)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_92"
              data-name="Línea 92"
              y2="294.62"
              transform="translate(147.31)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_93"
              data-name="Línea 93"
              y2="294.62"
              transform="translate(98.207)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <line
              id="Línea_94"
              data-name="Línea 94"
              y2="294.62"
              transform="translate(49.103)"
              fill="none"
              stroke="#2d293a"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </g>
      <path
        id="Trazado_33"
        data-name="Trazado 33"
        d="M34,476.382h0a13.143,13.143,0,0,1-1.989-18.511L147.515,313.3a13.143,13.143,0,0,1,18.511-1.989h0a13.143,13.143,0,0,1,1.989,18.511L52.513,474.393A13.143,13.143,0,0,1,34,476.382Z"
        transform="translate(-66.941 2364.908)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Trazado_34"
        data-name="Trazado 34"
        d="M84.4,467.082h0a13.143,13.143,0,0,1-1.989-18.511L197.915,304a13.143,13.143,0,0,1,18.511-1.989h0a13.143,13.143,0,0,1,1.989,18.511l-115.5,144.569A13.128,13.128,0,0,1,84.4,467.082Z"
        transform="translate(-117.342 2486.375)"
        fill="url(#linear-gradient-2)"
      />
      <circle
        id="Elipse_64"
        data-name="Elipse 64"
        cx="12.759"
        cy="12.759"
        r="12.759"
        transform="translate(108 2632.74)"
        fill="url(#linear-gradient)"
      />
      <circle
        id="Elipse_65"
        data-name="Elipse 65"
        cx="18.604"
        cy="18.604"
        r="18.604"
        transform="translate(227.791 2732.04)"
        fill="url(#linear-gradient-4)"
      />
    </g>
  </svg>
);

export { linesAndGrid };
