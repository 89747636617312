import * as React from "react";

const strokeBanner = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="240.661"
    height="220.447"
    viewBox="0 0 240.661 220.447"
    className={"a-banner__stroke"}
  >
    <path
      id="Trazado_24"
      data-name="Trazado 24"
      d="M226.9,199.914h0a12.864,12.864,0,0,1-1.947-18.118L338,40.3a12.864,12.864,0,0,1,18.118-1.947h0a12.864,12.864,0,0,1,1.947,18.118l-113.049,141.5A12.864,12.864,0,0,1,226.9,199.914Z"
      transform="translate(-222.087 17.668)"
      fill="#e6eaf7"
    />
    <path
      id="Trazado_25"
      data-name="Trazado 25"
      d="M294.9,164.414h0a12.864,12.864,0,0,1-1.947-18.118L406,4.8a12.864,12.864,0,0,1,18.118-1.947h0a12.864,12.864,0,0,1,1.946,18.118l-113.049,141.5A13.142,13.142,0,0,1,294.9,164.414Z"
      transform="translate(-188.268 0.013)"
      fill="#e6eaf7"
    />
    <circle
      id="Elipse_59"
      data-name="Elipse 59"
      cx="12.759"
      cy="12.759"
      r="12.759"
      transform="translate(76.3 173.583)"
      fill="#e6eaf7"
    />
  </svg>
);

export { strokeBanner };
