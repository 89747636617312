import "./services.scss";
import "./tabs.scss";
import "./navigator.scss";
import "./svgs.scss";

import BlockContent from "@sanity/block-content-to-react";
import { graphql, useStaticQuery } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import play from "../.././assets/vectors/ico-play_white.svg";
import { Contact } from "../../components/Forms/Contact/Contact";
import withForm from "../../components/Forms/withForm";
import ModalForm from "../../components/Modal/ModalForm";
import ModalMedia from "../../components/Modal/ModalMedia";
import { left, lines, right, stroke } from "./svg";

const Services: React.FC = () => {
  const { t } = useTranslation("Services");
  const sliderServices = useRef(null);
  const sliderServicesPhotos = useRef(null);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [isCanNext, setIsCanNext] = useState(true);
  const [isCanBack, setIsCanBack] = useState(false);
  const [is_activeForm, setIsActiveForm] = useState(false);
  const [is_activeMedia, setIsActiveMedia] = useState(false);
  const [from, setFrom] = useState("");
  const [titleMedia, setTitleMedia] = useState<string>("");
  const [codeVideo, setCodeVideo] = useState<string>("");
  const [photo, setPhoto] = useState<any>(null);
  const [is_videoMedia, setIsVideoMedia] = useState<boolean>(false);
  const ContactForm = withForm(Contact);
  const { allSanityService: services } = useStaticQuery(graphql`
    query GetServices {
      allSanityService(sort: { order: ASC, fields: position }) {
        nodes {
          photoZoom {
            asset {
              _id
              gatsbyImageData
            }
          }
          codeVideo
          photo {
            asset {
              _id
              gatsbyImageData
            }
          }
          name {
            en
            es
          }
          content {
            _rawEs
            _rawEn
            en {
              _rawChildren
            }
            es {
              _rawChildren
            }
          }
          cta {
            en
            es
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (currentPosition >= services.nodes.length - 1) {
      setIsCanNext(false);
    } else {
      setIsCanNext(true);
    }
    if (currentPosition === 0) {
      setIsCanBack(false);
    } else {
      setIsCanBack(true);
    }
  }, [currentPosition]);

  useLayoutEffect(() => {
    function resetSlider() {
      sliderServices.current.style.transform = `translateX(0px)`;
      sliderServicesPhotos.current.style.transform = `translateX(0px)`;
      setCurrentPosition(0);
    }

    function setHeightSlider() {
      const heightServiceSlider = document.getElementsByClassName("o-services")[0].clientHeight;
      document.body.style.setProperty("--height-o-service", `${heightServiceSlider}px`);
    }

    setHeightSlider();

    function resize() {
      resetSlider();
      setHeightSlider();
    }

    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  /* eslint-disable */
  const serializers = {
    types: {
      block: (props) => {
        const { style = "normal" } = props.node;
        if (style === "h1") {
          return <p className={"a-title__card-service"}>{props.children}</p>;
        }
        if (style === "h2") {
          return <h2 className={"a-subtitle__card-service"}>{props.children}</h2>;
        }
        return BlockContent.defaultSerializers.types.block(props);
      },
    },
  };
  /* eslint-enable */
  const nav_render = [];
  // const dots_render = [];
  const cards_render = [];
  const photos_render = [];

  function next() {
    goto(currentPosition + 1);
  }

  function back() {
    goto(currentPosition - 1);
  }

  const goto = (index: number) => {
    if (index <= sliderServices.current.children.length - 1 && index >= 0) {
      const element = sliderServices.current.children[index];
      const goService = element.offsetLeft;
      sliderServices.current.style.transition = `300ms linear all`;
      sliderServices.current.style.transform = `translateX(-${goService}px)`;
      sliderServicesPhotos.current.style.transition = `350ms linear all`;
      sliderServicesPhotos.current.style.transform = `translateX(-${goService}px)`;
      setCurrentPosition(index);
    }
  };

  const ctaClick = (servive: string) => {
    setIsActiveForm(true);
    setFrom(servive);
  };

  const openMediaModal = (title: string, photo: any, code_video: string | null) => {
    setTitleMedia(title);
    if (code_video) {
      setIsVideoMedia(true);
      setCodeVideo(code_video);
    } else {
      setIsVideoMedia(false);
    }
    if (photo) {
      setPhoto(photo);
    }
    setIsActiveMedia(true);
  };

  services.nodes.forEach((service, index) => {
    nav_render.push(
      <li
        key={`tab-${service.name.es}`}
        className={`a-services__tab ${currentPosition === index ? "a-services__tab--active" : ""} `}
        onClick={() => goto(index)}
      >
        <span className={"a-service__no-device"}>{service.name.es}</span>
        <span className={"a-service__in-device a-service__dot"}>o</span>
      </li>
    );

    photos_render.push(
      <div
        key={`photo-${service.name.es}`}
        className={"saveHeight__photo-card"}
        onClick={() => {
          openMediaModal(service.name.es, service.photoZoom, service.codeVideo);
        }}
      >
        {service.codeVideo && <img className={`a-service__play`} src={play} width={54} height={54} />}
        <SanityImage {...service.photo} alt={service.name.es} className={"a-service__photo"} width={960} height={539} />
      </div>
    );
    cards_render.push(
      <div key={`content-${service.name.es}`} className={"m-services__card"}>
        <div>
          <span className={"a-service-text a-service__in-device"}>{service.name.es}</span>
          <BlockContent blocks={service.content._rawEs} serializers={serializers} />
          <button
            className={"a-button a-button--third"}
            onClick={() => {
              ctaClick(service.name.es);
            }}
          >
            {" "}
            {service.cta.es}{" "}
          </button>
        </div>
      </div>
    );
  });

  return (
    <>
      {is_activeForm && (
        <ModalForm title={"Solicitar información"} from={from} is_active={setIsActiveForm} form={ContactForm} />
      )}
      {is_activeMedia && (
        <ModalMedia
          title={titleMedia}
          is_active={setIsActiveMedia}
          is_video={is_videoMedia}
          video_code={codeVideo}
          photo={photo}
        />
      )}
      <section className={"services-wrapper"}>
        {lines}
        <div className={"o-services"}>
          <ul className={"m-services__tabs"}>{nav_render}</ul>

          <div className="scroll-container">
            <div className={"scroll-container__content"} ref={sliderServices}>
              {cards_render}
            </div>
          </div>
          <div className="scroll-container scroll-container--photos">
            <div className={"scroll-container__content"} ref={sliderServicesPhotos}>
              {photos_render}
            </div>
          </div>
        </div>

        <div className={"m-services-navigator"}>
          <span
            onClick={() => {
              back();
            }}
            className={`m_services-navigator__button ${isCanBack ? "" : "m_services-navigator__button--disable"}`}
          >
            {left}
          </span>
          <span
            onClick={() => {
              next();
            }}
            className={`m_services-navigator__button ${isCanNext ? "" : "m_services-navigator__button--disable"}`}
          >
            {right}
          </span>
        </div>
        <div className={"backgroundPluses-pink"} />
        {stroke}
      </section>
    </>
  );
};

export default Services;
