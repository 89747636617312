import "./banner.scss";

import * as React from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

// import { StaticImage } from "gatsby-plugin-image";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../../assets/icons/logo-white.svg";
import { Contact } from "../../components/Forms/Contact/Contact";
import ModalForm from "../../components/Modal/ModalForm";
import { strokeBanner } from "./svgs";

const Banner: React.FC = () => {
  const { t } = useTranslation("global");
  const [is_active, setIsActive] = useState(false);
  const [from, setFrom] = useState("Solita una demo: Banner");

  const ctaFree = () => {
    setFrom("Prueba gratis: Banner");
    setIsActive(true);
  };

  const ctaDemo = () => {
    setIsActive(true);
  };

  return (
    <>
      {is_active && <ModalForm title={"Solicitar información"} from={from} is_active={setIsActive} form={Contact} />}
      <section className={"o-banner"}>
        <div className="backgroundPluses-pink" />
        <div className={"m-banner__container"}>
          <div className={"m-banner__content"}>
            <p>
              <Trans t={t} components={{ br: <br /> }}>
                banner.text
              </Trans>
            </p>
            <div className={"m-banner__buttons"}>
              <button className={"a-button a-button--third"} onClick={ctaDemo}>
                {t("banner.cta-a")}
              </button>
              {/*<button className={"a-button a-button--outline"} onClick={ctaFree}>*/}
              {/*  {t("banner.cta-b")}*/}
              {/*</button>*/}
            </div>
          </div>
          <img className={"a-banner__logo"} src={logo} alt={"Logotipo wayup"} width={359} height={219} />
        </div>
        {strokeBanner}
      </section>
    </>
  );
};

export default Banner;
