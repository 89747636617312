import "./advantages.scss";
import "./svgs.scss";

import * as React from "react";
import { Trans, useTranslation } from "react-i18next";

import { iconFlex, iconReliability, iconTime } from "./icons";
import { linesAndGrid } from "./svgs";

const Advantages: React.FC = () => {
  const { t } = useTranslation("global");
  return (
    <section className={"o-advantages"}>
      {linesAndGrid}
      <div>
        <h2 className={"a-title__advantages"}>{t("advantages.title")}</h2>
        <p>{t("advantages.description")}</p>
      </div>
      <div className={"m-advantages__feature"}>
        {iconTime}
        <div>
          <h3 className={"a-subtitle__advantages"}>
            {t("advantages.time.title")}
          </h3>
          <div className={"a-text__advantages"}>
            <Trans t={t} components={{ ul: <ul />, li: <li /> }}>
              advantages.time.features
            </Trans>
          </div>
        </div>
      </div>
      <div className={"m-advantages__feature"}>
        {iconFlex}
        <div>
          <h3 className={"a-subtitle__advantages"}>
            {t("advantages.flex.title")}
          </h3>
          <div className={"a-text__advantages"}>
            <Trans t={t} components={{ ul: <ul />, li: <li /> }}>
              advantages.flex.features
            </Trans>
          </div>
        </div>
      </div>
      <div className={"m-advantages__feature"}>
        {iconReliability}
        <div>
          <h3 className={"a-subtitle__advantages"}>
            {t("advantages.reliability.title")}
          </h3>
          <div className={"a-text__advantages"}>
            <Trans t={t} components={{ ul: <ul />, li: <li /> }}>
              advantages.reliability.features
            </Trans>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
