import { PropsWithChildren } from "react";
import * as React from "react";

interface PropsVimeo extends PropsWithChildren<any> {
  code: string;
  title: string;
  width?: number;
  height?: number;
}

const VimeoIframe: React.FC<PropsVimeo> = (props: PropsVimeo) => {
  const height: number = props.height ? props.height : 720;
  const width: number = props.width ? props.width : 1280;
  return (
    <div className="embed-iframe">
      <iframe
        width={width}
        className={"m-hero__video__content"}
        src={`https://player.vimeo.com/video/${props.code}/?autoplay=1&controls=0&loop=1&title=0&byline=0&background=1`}
        title={props.title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        scrolling="no"
        loading="lazy"
        allowFullScreen
      />
    </div>
  );
};

export default VimeoIframe;
