import "./modal-media.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import VimeoIframe from "../common/iframe/VimeoIframe";
import { close_svg } from "./elements_modal.jsx";

interface Props extends PropsWithChildren<any> {
  title: string;
  is_active: any;
  is_video: boolean;
  video_code?: string;
  photo?: any;
}

export const ModalForm: React.FC<Props> = (props: Props) => {
  return (
    <div className="o-modal-media">
      <div className={"m-modal-media__container"}>
        <div className={"o-modal-media__flex"}>
          <p className={"h2 a-modal-media__title"}>{props.title}</p>
          <span
            onClick={() => {
              props.is_active(false);
            }}
            className={"a-close"}
          >
            {close_svg}
          </span>
        </div>
        {props.is_video && props.video_code && <VimeoIframe code={props.video_code} title={props.title} />}
        {!props.is_video && props.photo && (
          <SanityImage
            {...props.photo}
            alt={`${props.title}`}
            className={"a-modal-media__photo-zoom"}
            style={{ maxWidth: "1920px" }}
            width={1920}
            height={1080}
          />
        )}
      </div>
    </div>
  );
};

export default ModalForm;
