import * as React from "react";

const left = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="15"
    viewBox="0 0 42.621 20.241"
  >
    <g
      id="Grupo_85"
      data-name="Grupo 85"
      transform="translate(560.621 2476.432) rotate(180)"
    >
      <line
        id="Línea_95"
        data-name="Línea 95"
        x2="37.53"
        transform="translate(519.5 2466.5)"
        fill="none"
        stroke="rgba(0,0,0,0.89)"
        strokeLinecap="round"
        strokeWidth="3"
      />
      <line
        id="Línea_97"
        data-name="Línea 97"
        y1="7.813"
        x2="8.47"
        transform="translate(550.03 2466.5)"
        fill="none"
        stroke="rgba(0,0,0,0.89)"
        strokeLinecap="round"
        strokeWidth="3"
      />
      <line
        id="Línea_98"
        data-name="Línea 98"
        x2="8.47"
        y2="8.188"
        transform="translate(550.03 2458.313)"
        fill="none"
        stroke="rgba(0,0,0,0.89)"
        strokeLinecap="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);

const right = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="15"
    viewBox="0 0 42.621 20.241"
  >
    <g id="Grupo_84" data-name="Grupo 84" transform="translate(-518 -2456.191)">
      <line
        id="Línea_95"
        data-name="Línea 95"
        x2="37.53"
        transform="translate(519.5 2466.5)"
        fill="none"
        stroke="rgba(0,0,0,0.89)"
        strokeLinecap="round"
        strokeWidth="3"
      />
      <line
        id="Línea_97"
        data-name="Línea 97"
        y1="7.813"
        x2="8.47"
        transform="translate(550.03 2466.5)"
        fill="none"
        stroke="rgba(0,0,0,0.89)"
        strokeLinecap="round"
        strokeWidth="3"
      />
      <line
        id="Línea_98"
        data-name="Línea 98"
        x2="8.47"
        y2="8.188"
        transform="translate(550.03 2458.313)"
        fill="none"
        stroke="rgba(0,0,0,0.89)"
        strokeLinecap="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);

const lines = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="240.661"
    height="220.447"
    viewBox="0 0 240.661 220.447"
    className={"a-services__lines"}
  >
    <g
      id="Grupo_87"
      data-name="Grupo 87"
      transform="translate(-351.238 -2442.417)"
    >
      <path
        id="Trazado_24"
        data-name="Trazado 24"
        d="M226.9,199.914h0a12.864,12.864,0,0,1-1.947-18.118L338,40.3a12.864,12.864,0,0,1,18.118-1.947h0a12.864,12.864,0,0,1,1.947,18.118l-113.049,141.5A12.864,12.864,0,0,1,226.9,199.914Z"
        transform="translate(129.151 2460.086)"
        fill="#e6eaf7"
      />
      <path
        id="Trazado_25"
        data-name="Trazado 25"
        d="M294.9,164.414h0a12.864,12.864,0,0,1-1.947-18.118L406,4.8a12.864,12.864,0,0,1,18.118-1.947h0a12.864,12.864,0,0,1,1.946,18.118l-113.049,141.5A13.142,13.142,0,0,1,294.9,164.414Z"
        transform="translate(162.97 2442.43)"
        fill="#e6eaf7"
      />
      <circle
        id="Elipse_59"
        data-name="Elipse 59"
        cx="12.759"
        cy="12.759"
        r="12.759"
        transform="translate(427.538 2616)"
        fill="#e6eaf7"
      />
    </g>
  </svg>
);

const stroke = (
  <svg xmlns="http://www.w3.org/2000/svg" width="405.829" height="302.562" viewBox="0 0 405.829 302.562" className={"a-services__stroke"}>
    <g id="Grupo_181" data-name="Grupo 181" transform="translate(-358.707 -4850.745)">
      <g id="_1" data-name="1" transform="translate(561.706 5153.296) rotate(-157)">
        <path id="Trazado_23" data-name="Trazado 23" d="M79.989,224.094c-21.446-5.241-42.65-16.566-57.71-38.554a97.946,97.946,0,0,1-14.94-34.879,125.484,125.484,0,0,1-1.807-36.506c2.108-25.3,9.4-49.277,15.06-73.373C23.664,27.59,26.435,13.855,27.58,0H22.64c-.12,1.626-.3,3.193-.482,4.819-3.072,25.783-10.723,50-16.2,74.939C.773,103.553-2.721,129.276,2.7,153.553c6.024,26.807,21.566,48.132,39.638,60.9,20.843,14.7,44.819,19.458,68.132,20.421,25.662,1.084,51.265-2.651,76.867-4.879a319.211,319.211,0,0,1,33.192-1.265v-6.867C173.723,220.962,126.555,235.48,79.989,224.094Z" transform="translate(0)" fill="#dc595f"/>
      </g>
    </g>
  </svg>
);

export { left, lines, right, stroke };
