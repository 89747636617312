import * as React from "react";

const iconTime = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47.833"
    height="47.833"
    viewBox="0 0 47.833 47.833"
  >
    <g id="clock-svgrepo-com" transform="translate(-1.9 -1.88)">
      <path
        id="Trazado_26"
        data-name="Trazado 26"
        d="M25.816,49.713a23.94,23.94,0,1,1,16.888-7A23.917,23.917,0,0,1,25.816,49.713Zm0-44.281a20.389,20.389,0,1,0,7.8,1.561A20.365,20.365,0,0,0,25.816,5.432Z"
        transform="translate(0 0)"
        fill="#99c9ce"
      />
      <path
        id="Trazado_27"
        data-name="Trazado 27"
        d="M19.68,29.087a1.682,1.682,0,0,1-1.255-.521l-6.654-6.63a1.776,1.776,0,0,1-.521-1.255V7.4a1.776,1.776,0,0,1,3.552,0v12.55l6.133,6.109a1.776,1.776,0,0,1,0,2.51,1.751,1.751,0,0,1-1.255.521Z"
        transform="translate(12.79 5.116)"
        fill="#99c9ce"
      />
    </g>
  </svg>
);
const iconFlex = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40.5"
    height="48.002"
    viewBox="0 0 40.5 48.002"
  >
    <g id="path-svgrepo-com" transform="translate(-2.94 -1.25)">
      <path
        id="Trazado_30"
        data-name="Trazado 30"
        d="M27.353,39.649H16.19c-4.019,0-11.61-2.032-11.61-9.734s7.591-9.734,11.61-9.734H26.1c.335,0,8.261-.112,8.261-6.385S26.438,7.409,26.1,7.409H14.94a1.674,1.674,0,1,1,0-3.349H26.1c4.019,0,11.61,2.032,11.61,9.734s-7.591,9.734-11.61,9.734H16.19c-.335,0-8.261.112-8.261,6.385S15.855,36.3,16.19,36.3H27.353a1.674,1.674,0,0,1,0,3.349Z"
        transform="translate(2.022 3.464)"
        fill="#99c9ce"
      />
      <path
        id="Trazado_31"
        data-name="Trazado 31"
        d="M10.754,17.012a7.881,7.881,0,1,1,3.037-.581A7.882,7.882,0,0,1,10.754,17.012Zm0-12.414a4.465,4.465,0,1,0,1.709.34A4.465,4.465,0,0,0,10.754,4.6Z"
        transform="translate(0)"
        fill="#99c9ce"
      />
      <path
        id="Trazado_32"
        data-name="Trazado 32"
        d="M21.924,31.453a7.882,7.882,0,1,1,5.558-2.316A7.881,7.881,0,0,1,21.924,31.453Zm0-12.414a4.465,4.465,0,1,0,3.157,1.308A4.464,4.464,0,0,0,21.924,19.039Z"
        transform="translate(13.658 17.799)"
        fill="#99c9ce"
      />
    </g>
  </svg>
);
const iconReliability = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="47.844"
    viewBox="0 0 46 47.844"
  >
    <g id="check-circle-svgrepo-com" transform="translate(-2.65 -2.25)">
      <path
        id="Trazado_28"
        data-name="Trazado 28"
        d="M26.572,50.094a23.922,23.922,0,1,1,0-47.844,1.84,1.84,0,0,1,0,3.68,20.258,20.258,0,1,0,14.4,34.447,1.848,1.848,0,1,1,2.625,2.6,23.775,23.775,0,0,1-17.027,7.115Z"
        transform="translate(0 0)"
        fill="#99c9ce"
      />
      <path
        id="Trazado_29"
        data-name="Trazado 29"
        d="M18.977,30.056a1.815,1.815,0,0,1-1.3-.54L7.863,19.7a1.84,1.84,0,0,1,2.6-2.6l8.514,8.514L38.605,5.987a1.84,1.84,0,1,1,2.6,2.6L20.351,29.443a1.79,1.79,0,0,1-1.374.613Z"
        transform="translate(6.859 4.58)"
        fill="#99c9ce"
      />
    </g>
  </svg>
);

export { iconFlex, iconReliability, iconTime };
