import "./plans.scss";
import "./decorative-plans.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../../assets/icons/logo.svg";
import { Contact } from "../Forms/Contact/Contact";
import ModalForm from "../Modal/ModalForm";
import { strokeAndCircle, strokesAndGrid } from "./svgs";
import TablePlans from "./table/TablePlans";

const Plans: React.FC = () => {
  const { t } = useTranslation("global");

  const { allSanityPlan: plans } = useStaticQuery(graphql`
    query getPlans {
      allSanityPlan(sort: { order: ASC, fields: position }) {
        nodes {
          _id
          position
          color
          name
          price
          detail {
            es
          }
          description {
            es
          }
          features {
            _id
          }
        }
      }
    }
  `);
  const [selectedPlan, setSelectedPlan] = useState(plans.nodes[0]);
  const [is_active, setIsActive] = useState(false);

  const goto = (anchor) => {
    document.getElementById(anchor).scrollIntoView({ block: "center", behavior: "smooth", inline: "end" });
  };

  const plans_cards_render = [];

  plans.nodes.forEach((plan) => {
    plans_cards_render.push(
      <div
        key={plan._id}
        className={`m-plans__card ${selectedPlan._id === plan._id ? "m-plans__card--active" : ""}`}
        style={{ outlineColor: `${plan.color}` }}
      >
        <div className="m-plans__card__heading">
          <img className={"a-plans__logo"} src={logo} alt={""} width={"100"} />
          <h3 className="a-plans__name" style={{ color: plan.color }}>
            {plan.name}
          </h3>
        </div>

        <p className="a-plans__description">{plan.description.es}</p>
        <button
          className={"a-button a-button--fullwidth"}
          onClick={() => {
            setSelectedPlan(plan);
            goto(plan.name);
          }}
          style={{ backgroundColor: plan.color }}
        >
          {" "}
          {t("plans.cta-a")}{" "}
        </button>
        <span className={"a-plans__cta-b"}> {plan.detail.es} </span>
      </div>
    );
  });

  function changePlan(num: number) {
    const nextSelected = plans.nodes.indexOf(selectedPlan) + num;
    const { length } = plans.nodes;
    if (nextSelected === plans.nodes.length) {
      setSelectedPlan(plans.nodes[0]);
      return;
    }
    if (nextSelected < 0) {
      setSelectedPlan(plans.nodes[plans.nodes.length - 1]);
      return;
    }
    setSelectedPlan(plans.nodes[nextSelected]);
  }

  return (
    <>
      {is_active && (
        <ModalForm title={"Solicitar información"} from={"Consulta servicio"} is_active={setIsActive} form={Contact} />
      )}
      <section className={"o-plans"}>
        <div className={"m-plans__header"}>
          <div className="d-wrapper">
            <div className="d-plan-back" />
            {strokeAndCircle}
          </div>
          <div className="m-plans__content ">
            <h2 className={"a-title__plans"}>{t("plans.title")}</h2>
            <ScrollContainer>
              <div className="m-plans__cards">{plans_cards_render}</div>
            </ScrollContainer>
          </div>
        </div>
        <ScrollContainer>
          <TablePlans
            id={"table-plans"}
            plans={plans.nodes}
            selectedPlan={selectedPlan}
            changePlan={changePlan}
            openModal={setIsActive}
          />
        </ScrollContainer>
      </section>
    </>
  );
};

export default Plans;
