import React, { useState } from "react";

import Layout from "../views/shared/layout/layout";
import Hero from "../views/hero/Hero";
import Brands from "../views/brands/Brands";
import Services from "../views/services/Services";
import Advantages from "../views/advantages/Advantages";
import Plans from "../components/plans/Plans";
// import Reviews from "../components/GMB/Reviews";
import Banner from "../views/banner/Banner";
import ModalForm from "../components/Modal/ModalForm";
import { Contact } from "../components/Forms/Contact/Contact";
import { SEO } from "../components/SEO/SEO";

const IndexPage = () => {
  const [is_active, setIsActive] = useState(false);
  const [from, setFrom] = useState("Solita una demo: Hero")

  // const ctaFree = () => {
  //   setFrom("Prueba gratis: Hero");
  //   setIsActive(true);
  // }

  const ctaDemo = () =>{
    setIsActive(true);
  }

  const ctas = [
    <button key={"demo"} className={"a-button"} onClick={ctaDemo}>Solicita una demo</button>,
    // <button key={"test"} className={"a-button a-button--secondary"} onClick={ctaFree}>Prueba gratis</button>,
  ];
  const heroContent = {
    title: "El primer Configurador web de Ascensores del mercado",
    text: "Hecho por expertos y para todo el Sector del Ascensor",
    ctas: ctas,
  };

  return (
    <Layout lang={"es"}>
      <SEO />
      { is_active && <ModalForm title={"Solicitar información"} from={from} is_active={setIsActive} form={Contact} /> }
      <Hero content={heroContent} video={"696945044"} />
      <Brands />
      <Services />
      <Advantages />
      <Plans />
      {/*<Reviews />*/}
      <Banner />
    </Layout>
  );
};

export default IndexPage;
