import "./tooltip.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

interface PropsTooltip extends PropsWithChildren<any> {
  sms: string;
  title: string;
  active: boolean;
  position: number[];
  setActive: any;
}

const Tooltip: React.FC<PropsTooltip> = (props: PropsTooltip) => {
  return (
    <div
      onClick={() => props.setActive(false)}
      className={`m-tooltip ${props.active ? "" : "hidden"}`}
      style={{ left: props.position[0], top: props.position[1] }}
    >
      <p className={"a-title a-title__card-service"}>{props.title}</p>
      <p>{props.sms}</p>
    </div>
  );
};

export default Tooltip;
