import "./brands.scss";

import { graphql, useStaticQuery } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { useCallback, useEffect, useRef } from "react";

const Brands: React.FC = () => {
  const { allSanityBrand: brands } = useStaticQuery(graphql`
    query AllBrands {
      allSanityBrand {
        nodes {
          name
          logo {
            ...ImageWithPreview
          }
        }
      }
    }
  `);
  const carousel = useRef(null);
  const intervaloCarousel = useRef(null);

  const brands_render = [];

  useEffect(() => {
    intervaloCarousel.current = setInterval(() => {
      next();
    }, 0);
    carousel.current.addEventListener("mouseenter", () => {
      clearInterval(intervaloCarousel.current);
    });

    carousel.current.addEventListener("mouseleave", () => {
      intervaloCarousel.current = setInterval(() => {
        next();
      }, 0);
    });
  }, []);

  const next = useCallback(() => {
    const firstBrand = carousel.current.children[0];
    const SecondBrand = carousel.current.children[1];
    carousel.current.style.transition = `6000ms linear all`;
    const nextBrand = SecondBrand.offsetLeft;
    carousel.current.style.transform = `translateX(-${nextBrand}px)`;
    const transition = () => {
      carousel.current.style.transition = "none";
      carousel.current.style.transform = "translateX(0)";
      carousel.current.appendChild(firstBrand);
      carousel.current.removeEventListener("transitionend", transition);
    };
    carousel.current.addEventListener("transitionend", transition);
  }, []);

  useEffect(() => {}, []);

  brands.nodes.forEach((brand, index) => {
    brands_render.push(
      <div key={index} className={"m-brand__box"}>
        <SanityImage
          {...brand.logo}
          alt={brand.name}
          className={"a-brand "}
          width={150}
        />
      </div>
    );
  });

  return (
    <section className={"o-brands"}>
      <div className="scroll-container" id={"brandsCarrusel"}>
        <div className={"scroll-container__content"} ref={carousel}>
          {brands_render}
        </div>
      </div>
    </section>
  );
};

export default Brands;
